<div class="text-input">
  <div *ngIf="required" class="required-indicator">*</div>
  <input
    (blur)="clickOutside.emit()"
    (click)="onTouched()"
    (input)="updateValue($event)"
    (keydown.enter)="update.emit()"
    [(ngModel)]="innerValue"
    [attr.id]="inputId ? inputId : null"
    [ngClass]="classes"
    [placeholder]="placeholder"
    [readonly]="readOnly"
    [type]="type"
    autocomplete="on" />
</div>
