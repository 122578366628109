import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  InputType,
  textAlignType,
  textColorsType,
  widthType,
} from '@libs/input/input.types';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  public innerValue!: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onTouched: any;
  public isDisabled = false;
  @Input() type: InputType = 'text';
  @Input() width: widthType = 'medium';
  @Input() size: 'small' | 'medium' | 'large' | 'fit' = 'medium';
  @Input() rounded: 'true' | 'false' = 'false';
  @Input() placeholder = '';
  @Input() invalid: true | false = false;
  @Input() textColor: textColorsType = 'text-black';
  @Input() readOnly = false;
  @Input() textAlign: textAlignType = 'text-left';
  @Input() inputId?: string;
  @Input() required = false;
  @Output() update = new EventEmitter<void>();
  @Output() clickOutside = new EventEmitter<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: any;

  constructor(private cdr: ChangeDetectorRef) {}

  public get classes(): string[] {
    const classes: string[] = [
      this.width,
      this.textColor,
      this.textAlign,
      this.size,
    ];
    if (this.rounded === 'true') {
      classes.push('rounded');
    }
    return classes;
  }

  public writeValue(value: string) {
    this.innerValue = value;
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  public updateValue(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    const parsedValue = parseFloat(inputValue);
    if (this.type === 'number' && !isNaN(parsedValue)) {
      this.innerValue = parsedValue;
    } else if (this.type === 'number') {
      this.innerValue = inputValue;
    }
    this.onChange(this.innerValue);
  }
}
